<template>
  <div class="markdown-container" :style="$vuetify.theme.dark ? 'color: white' : 'color:black'">
    <main :class="$vuetify.breakpoint.smAndUp ? 'markdown-wrapper-desktop' : 'markdown-wrapper-mobile'">
      <aside id="markdown-aside" class="pt-5 markdown-sticky mt-3" v-show="$vuetify.breakpoint.smAndUp"></aside>
      <div id="markdown-content"></div>
    </main>
  </div>
</template>

<script>
import { marked } from 'marked'

export default {
  props: {
    description: String,
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async description(description) {
      if (!this.description) return
      await this.init()
    },
  },
  computed: {
    headerHeight() {
      return this.$vuetify.breakpoint.width < 1050 ? 66 : 92
    },
  },
  methods: {
    async fetchAndParseMarkdown() {
      const data = this.description
      const htmlFromMarkdown = marked(data)
      return htmlFromMarkdown
    },
    generateLinkMarkup($headings) {
      const parsedHeadings = $headings.map((heading) => {
        return {
          title: heading.innerText,
          depth: heading.nodeName.replace(/\D/g, ''),
          id: heading.getAttribute('id'),
        }
      })
      const htmlMarkup = parsedHeadings.map(
        (h) =>
          `
          <li class="${h.depth > 1 ? 'pl-4 custom-link-item' : 'custom-link-item'}">
          	<a headingTag="#${h.id}">${h.title}</a>
          </li>
          `
      )
      const finalMarkup = `
		<ul class="markdown-ul">${htmlMarkup.join('')}</ul>
	`
      return finalMarkup
    },
    updateLinks(visibleId, $links) {
      $links.map((link) => {
        let headingTag = link.getAttribute('headingTag')
        link.classList.remove('markdown-is-active')
        if (headingTag === `#${visibleId}`) link.classList.add('markdown-is-active')
      })
    },
    async init() {
      // Part 1
      const $main = document.querySelector('#markdown-content')
      const $aside = document.querySelector('#markdown-aside')
      const htmlContent = await this.fetchAndParseMarkdown()
      $main.innerHTML = htmlContent
      // Part 2
      const $headings = [...$main.querySelectorAll('h1, h2')]
      const linkHtml = this.generateLinkMarkup($headings)
      $aside.innerHTML = linkHtml
      const $links = [...$aside.querySelectorAll('a')]
      $links.forEach((link) => {
        link.addEventListener('click', () => {
          $headings.forEach((heading) => {
            if (`#${heading.id}` === link.getAttribute('headingTag')) {
              heading.scrollIntoView()
              if (!(window.innerHeight + window.scrollY >= document.body.offsetHeight))
                window.scrollTo({
                  top: window.scrollY - this.headerHeight,
                })
            }
          })
        })
      })
      document.addEventListener('scroll', () => {
        this.onScroll($headings, $links)
      })
    },

    onScroll($headings, $links) {
      if (!$headings || !$links) return
      if ($headings[0].getBoundingClientRect().top >= 100) this.updateLinks('#', $links)
      $headings.forEach((heading) => {
        if (heading.getBoundingClientRect().top <= 100 && heading.getBoundingClientRect().top > 0)
          this.updateLinks(heading.id, $links)
      })
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100)
        this.updateLinks($headings[$headings.length - 1].id, $links)
    },
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
}
</script>
<style lang="scss">
#markdown-content {
  overflow-x: hidden;
  overflow-wrap: break-word;
  /* white-space: pre-wrap !important; */
}
#markdown-content ::v-deep pre code.language-sh {
  white-space: pre-wrap !important;
}
#markdown-aside ::v-deep .custom-link-item {
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: white !important;
  }
}
.custom-link-item {
  line-height: 35px;
}
#markdown-content p {
  max-width: 100% !important;
  overflow-x: hidden;
}
/* p {
  line-height: 200% !important;
} */
#markdown-content p img {
  max-width: 100% !important;
  margin: 10px 0;
}
#markdown-content ul li {
  line-height: 3;
  font-size: 16px !important;
}
#markdown-content p {
  font-size: 16px !important;
  margin-bottom: 0;
  line-height: 2;
}
#markdown-content h1 {
  padding-bottom: 15px;
}
#markdown-content h1:not(:first-child) {
  padding-top: 15px;
}
.custom-link-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme--dark {
  .custom-link-item .markdown-is-active {
    color: white !important;
  }
  .custom-link-item a:not(.markdown-is-active) {
    color: gray !important;
    font-size: 16px;
    font-weight: 400 !important;
  }
}
.them--light {
  .custom-link-item .markdown-is-active {
    color: #f47a60 !important;
  }
  .custom-link-item a:not(.markdown-is-active) {
    color: gray !important;
    font-size: 16px;
    font-weight: 400 !important;
  }
}
</style>
